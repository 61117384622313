import React from "react";
import TermsConditions from "../Common/TermsConditions";
import Footer from "../Common/Footer";

class TermsConditionsPage extends React.Component {
  render() {
    return (
      <>
        {/* Welcome Area */}
        <TermsConditions />

        {/* Footer Area */}
        <Footer />
      </>
    );
  }
}

export default TermsConditionsPage;

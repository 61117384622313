import React from "react";

class TermsConditions extends React.Component {
  render() {
    return (
      <section id="services" className="services-area ptb-80">
        <div className="container">
          <div className="section-title">
            <h2>
              Terms <span>&amp;</span> Conditions
            </h2>
            <p><strong>Effective Date:</strong> March 26 2025</p>
            <p>
                <strong>Disclaimer:</strong> These Terms &amp; Conditions (“T&amp;Cs”) are provided for 
                informational purposes only and do not constitute legal advice. It is recommended 
                that you consult a qualified attorney licensed in New Hampshire to ensure these T&amp;Cs 
                comply with all applicable federal, state, and local laws and regulations.
            </p>

            <h2>1. Introduction</h2>
            <ol>
                <li>
                    <strong>Parties.</strong> These Terms &amp; Conditions (“T&amp;Cs” or “Agreement”) govern 
                    the relationship between <strong>Arch Technologies LLC</strong> (“Company,” “we,” 
                    “us,” or “our”) and any individual or entity (“Client,” “you,” or “your”) that 
                    purchases or uses our services, including but not limited to web development, 
                    marketing, social media management, Voice over Internet Protocol (VoIP) services, 
                    and Facilities-Based Competitive Local Exchange Carrier (“CLEC”) services in the 
                    State of New Hampshire.
                </li>
                <li>
                    <strong>Acceptance.</strong> By signing a written contract referencing these T&amp;Cs, 
                    by using our website(s), or by otherwise accessing or using any of our services, 
                    you agree to be bound by these T&amp;Cs, as well as all applicable state and federal 
                    regulations.
                </li>
                <li>
                    <strong>Amendments.</strong> We reserve the right to modify or replace these T&amp;Cs 
                    at any time. In such cases, we will provide notice via email, a notice on our 
                    website, or through other reasonable means. By continuing to use the services 
                    after amendments become effective, you accept the revised terms.
                </li>
            </ol>

            <h2>2. Services Description</h2>
            <ol>
                <li>
                    <strong>Web Development and Marketing.</strong> This may include, but is not limited to, 
                    the design, creation, hosting, and maintenance of websites, development of digital 
                    marketing campaigns, search engine optimization (SEO), and other related services.
                </li>
                <li>
                    <strong>Social Media Management.</strong> Includes managing your social media accounts, 
                    publishing content, and, where applicable, strategizing for customer engagement.
                </li>
                <li>
                    <strong>VoIP Services.</strong> Includes the provision of voice communications, calling 
                    plans, and other related telecommunications services using internet protocols.
                </li>
                <li>
                    <strong>CLEC Services.</strong> We may operate as a Facilities-Based Competitive Local 
                    Exchange Carrier under applicable federal and New Hampshire law. This may include 
                    the provision of local telephone exchange services, data services, and connectivity 
                    solutions, subject to necessary regulatory approvals.
                </li>
                <li>
                    <strong>Service Limitations.</strong> The nature, scope, and specifications of any 
                    service may be outlined further in your specific service contract or statement of 
                    work (“SOW”). Any service provided is subject to availability, network capacity, 
                    and coverage. We reserve the right to modify, suspend, or discontinue services, 
                    in whole or in part, when reasonably necessary to protect network integrity, 
                    address compliance issues, or for reasons outside of our control.
                </li>
            </ol>

            <h2>3. Regulatory Compliance</h2>
            <ol>
                <li>
                    <strong>Federal and State Regulations.</strong> Our CLEC services and VoIP services 
                    are provided in compliance with all applicable laws and regulations, including 
                    Title 47 of the U.S. Code (Telecommunications), rules of the Federal Communications 
                    Commission (FCC), and the New Hampshire Public Utilities Commission (NHPUC). We 
                    comply with all relevant tariffs, including those outlined by the National Exchange 
                    Carrier Association (NECA), as applicable.
                </li>
                <li>
                    <strong>Tariffs and Surcharges.</strong> Certain services may be subject to state and 
                    federal surcharges, taxes, fees, or contributions (e.g., Universal Service Fund (USF) 
                    contributions). These will be detailed in your service invoice or statement of work. 
                    You are responsible for all such fees, taxes, and surcharges as required by law.
                </li>
                <li>
                    <strong>Licensing and Approvals.</strong> Where required, we maintain the licenses, 
                    approvals, and permits necessary to operate as a CLEC in New Hampshire. Should 
                    any license or approval be suspended or terminated, we will notify you and work 
                    with you to mitigate any service disruption.
                </li>
                <li>
                    <strong>Emergency Services (E911).</strong> For VoIP services, we provide access to 
                    E911 services in accordance with FCC and state requirements. Due to the nature 
                    of VoIP technology, it is your responsibility to register and update your correct 
                    service address for E911 purposes. We disclaim liability for any failure in 
                    emergency communications resulting from inaccurate or outdated address information 
                    or for issues outside of our control (e.g., power loss or broadband internet 
                    disruption).
                </li>
            </ol>

            <h2>4. Client Obligations</h2>
            <ol>
                <li>
                    <strong>Information Accuracy.</strong> You agree to provide accurate and up-to-date 
                    information, including billing and contact information, and to promptly notify us 
                    of any changes.
                </li>
                <li>
                    <strong>Acceptable Use.</strong> You may use our services only for lawful purposes 
                    and in accordance with these T&amp;Cs. You agree not to use our services to:
                    <ul>
                        <li>Violate any federal, state, or local law or regulation.</li>
                        <li>Infringe upon or violate the intellectual property or privacy rights of 
                            any third party.</li>
                        <li>Engage in fraudulent, harassing, or inappropriate activities (including 
                            spam, hacking, or attempts to disrupt the integrity or security of our or 
                            others’ networks).</li>
                    </ul>
                </li>
                <li>
                    <strong>Equipment and Network.</strong> You are responsible for providing and 
                    maintaining any necessary equipment (e.g., compatible telephone, networking 
                    hardware, or computing devices) and internet connectivity for receiving the 
                    services. Unless otherwise specified, we are not responsible for the maintenance, 
                    operation, or security of your internal networks or equipment.
                </li>
                <li>
                    <strong>Timely Payments.</strong> All fees must be paid in full by the due date as 
                    set forth on your invoice. Late or missed payments may result in additional fees, 
                    service suspension, or termination, as permitted by law.
                </li>
            </ol>

            <h2>5. Intellectual Property Rights</h2>
            <ol>
                <li>
                    <strong>Ownership.</strong> All intellectual property rights, including but not 
                    limited to copyrights, trademarks, service marks, and trade secrets, in and to 
                    our services, software, and documentation, are owned by us or our licensors.
                </li>
                <li>
                    <strong>License.</strong> Subject to your compliance with these T&amp;Cs, we grant 
                    you a limited, non-exclusive, non-transferable license to access and use our 
                    proprietary materials only for the purposes outlined in your contract or 
                    statement of work.
                </li>
                <li>
                    <strong>Client Materials.</strong> You retain all rights in any intellectual 
                    property you provide to us for the purposes of performing the services 
                    (e.g., logos, textual content). By providing such materials, you grant 
                    us a non-exclusive, worldwide, royalty-free license to use, copy, modify, 
                    and display such materials as necessary to provide the services.
                </li>
            </ol>

            <h2>6. Confidentiality and Privacy</h2>
            <ol>
                <li>
                    <strong>Confidential Information.</strong> Each party agrees to protect all 
                    confidential information disclosed by the other party and not to disclose 
                    such information to any third parties except as necessary to perform 
                    obligations under these T&amp;Cs or as required by law.
                </li>
                <li>
                    <strong>Data Protection.</strong> We follow industry standards to protect your 
                    data from unauthorized access, disclosure, or alteration. However, no method 
                    of transmission or storage is 100% secure, and we disclaim liability for any 
                    unauthorized access to your data arising outside of our control.
                </li>
                <li>
                    <strong>Privacy Policy.</strong> Our collection, use, and disclosure of personal 
                    information are governed by our Privacy Policy, which you can find on our 
                    website. By using our services, you acknowledge that you have read and consent 
                    to our Privacy Policy.
                </li>
            </ol>

            <h2>7. Disclaimers and Limitations of Liability</h2>
            <ol>
                <li>
                    <strong>Disclaimer of Warranties.</strong> Except as expressly stated in these 
                    T&amp;Cs, the services are provided “AS IS” and “AS AVAILABLE.” To the maximum 
                    extent allowed by law, we disclaim all warranties, whether express, implied, 
                    statutory, or otherwise, including any warranties of merchantability, fitness 
                    for a particular purpose, and non-infringement.
                </li>
                <li>
                    <strong>Limitation of Liability.</strong> To the fullest extent permitted by law, 
                    neither party shall be liable to the other for consequential, indirect, punitive, 
                    special, or incidental damages, or lost profits, arising out of or related to 
                    these T&amp;Cs. In no event shall our total liability, whether in contract, tort, 
                    or otherwise, exceed the fees paid by you to us in the three (3) months preceding 
                    the event giving rise to the claim.
                </li>
                <li>
                    <strong>Indemnification.</strong> You agree to indemnify, defend, and hold us 
                    harmless from and against any third-party claims, damages, liabilities, costs, 
                    and expenses (including reasonable attorney fees) arising from your use of the 
                    services, breach of these T&amp;Cs, or violation of any law or regulation.
                </li>
            </ol>

            <h2>8. Termination</h2>
            <ol>
                <li>
                    <strong>By Company.</strong> We may suspend or terminate your service if 
                    (a) you fail to cure any material breach of these T&amp;Cs within thirty (30) days 
                    after receiving notice, (b) you fail to pay overdue amounts after notice, 
                    (c) we are directed by a regulatory authority to cease operations, or 
                    (d) continuing to provide services would violate any applicable law.
                </li>
                <li>
                    <strong>By Client.</strong> You may terminate the services for convenience, subject 
                    to any obligations (including early termination fees) contained in your specific 
                    contract or SOW, or for our material breach that remains uncured thirty (30) days 
                    following notice.
                </li>
                <li>
                    <strong>Effect of Termination.</strong> Upon termination, you must cease using all 
                    services and promptly pay all outstanding fees through the effective date of 
                    termination. Any accrued rights and obligations shall survive the termination 
                    of these T&amp;Cs.
                </li>
            </ol>

            <h2>9. Dispute Resolution and Governing Law</h2>
            <ol>
                <li>
                    <strong>Governing Law.</strong> These T&amp;Cs and any dispute or claim arising out 
                    of or in connection with them shall be governed by and construed in accordance 
                    with the laws of the State of New Hampshire, without regard to its conflict of 
                    law provisions.
                </li>
                <li>
                    <strong>Venue.</strong> Any suit, action, or proceeding arising out of or relating 
                    to these T&amp;Cs shall be instituted in the federal or state courts located in 
                    New Hampshire, and each party irrevocably submits to the exclusive jurisdiction 
                    of such courts.
                </li>
                <li>
                    <strong>Alternative Dispute Resolution.</strong> The parties may agree, in good faith, 
                    to attempt to settle disputes through mediation or arbitration before proceeding 
                    with a lawsuit, if mutually acceptable.
                </li>
            </ol>

            <h2>10. Miscellaneous</h2>
            <ol>
                <li>
                    <strong>Independent Contractors.</strong> The parties are independent contractors, 
                    and nothing in these T&amp;Cs shall be construed to create a partnership, joint 
                    venture, or agency relationship.
                </li>
                <li>
                    <strong>Force Majeure.</strong> We shall not be liable for delays or failures in 
                    performance of our obligations due to causes beyond our reasonable control, such 
                    as acts of God, natural disasters, war, terrorism, labor disputes, or government 
                    actions.
                </li>
                <li>
                    <strong>Severability.</strong> If any provision of these T&amp;Cs is held to be invalid 
                    or unenforceable, that provision will be enforced to the maximum extent permissible, 
                    and the remaining provisions shall remain in full force and effect.
                </li>
                <li>
                    <strong>No Waiver.</strong> Our failure to exercise or enforce any right or provision 
                    of these T&amp;Cs shall not constitute a waiver of such right or provision.
                </li>
                <li>
                    <strong>Notices.</strong> All notices under these T&amp;Cs shall be given in writing 
                    and delivered to the addresses provided by the parties or through other verifiable 
                    means (e.g., email with confirmation).
                </li>
                <li>
                    <strong>Entire Agreement.</strong> These T&amp;Cs, along with any executed contract 
                    or SOW, represent the entire agreement between the parties relating to the services 
                    and supersede all prior or contemporaneous agreements, communications, and proposals, 
                    whether written or oral, with respect to their subject matter.
                </li>
            </ol>

            <hr />

            <div class="contact-info">
                <p><strong>Arch Technologies LLC</strong></p>
                <p>35 Bedard Ave<br />Derry, NH 03038</p>
                <p>Phone: (978) 377-1062</p>
                <p>Email: help@archtechnologies.com</p>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default TermsConditions;
